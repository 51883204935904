/**
 * A document describing each event can be found here:
 *
 * https://docs.google.com/document/d/1Oab_s89fJhpLlJ417DqXnZ-bEJDX7HSJDe4NE6tkudU/edit?tab=t.0
 *
 * If you make any changes to this file, please remember to update the document accordingly.
 */

import { BuildMode } from "@/types";

export enum EventName {
  RegistrationDone = "registration-done",
  ItemAdded = "item-added",
  ItemDeleted = "item-deleted",
  ShareProfileBox = "share profile box",
  ItemSoldDialogConfirm = "item-sold-dialog-confirm",
  MessagingIWantToTalkClick = "messages-funnel-click",
  MessagingConversationStarted = "messages-funnel-first-message",
  MessagingFirstReaction = "messages-funnel-first-reaction",
  ArticleBanner = "magazine banner",
}

export enum ShareProfileBoxSource {
  InputClick = "click input",
  CopyButtonClick = "copy button",
  ShowProfileButtonClick = "show profile",
}
export enum ItemSoldDialogConfirmOption {
  Sold = "sold",
  Deleted = "deleted",
}
export enum ArticleBannerType {
  Basic = "basic",
}
export enum ArticleBannerSource {
  Click = "click",
}
export enum ItemAddedSource {
  ViaAutocomplete = "via-autocomplete",
}

export type Event =
  | {
      name: EventName.RegistrationDone | EventName.ItemDeleted | EventName.MessagingConversationStarted | EventName.MessagingIWantToTalkClick | EventName.MessagingFirstReaction;
      params?: undefined; // No additional params
    }
  | {
      name: EventName.ShareProfileBox;
      params: {
        source: ShareProfileBoxSource;
      };
    }
  | {
      name: EventName.ItemSoldDialogConfirm;
      params: {
        option: ItemSoldDialogConfirmOption;
      };
    }
  | {
      name: EventName.ArticleBanner;
      params: {
        bannerType: ArticleBannerType;
        source: ArticleBannerSource;
      };
    }
  | {
      name: EventName.ItemAdded;
      params?: {
        source: ItemAddedSource;
      };
    };

const mode = import.meta.env.MODE;

export function trackEvent(event: Event): void {
  if (mode !== BuildMode.production) {
    return; // Track only in production
  }

  if (typeof window.umami === "undefined") {
    throw new Error("DEV: Should not happen. Umami not found.");
  }

  window.umami.track(event.name, event.params);
}
