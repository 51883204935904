import about from "./views/about.sk";
import addItem from "./views/addItem.sk";
import contacts from "./views/contacts.sk";
import userSettings from "./views/userSettings.sk";
import publicHomepage from "./views/publicHomepage.sk";
import itemDetail from "./views/itemDetail.sk";
import landingPages from "./views/landingPages.sk";
import magazine from "./views/magazine.sk";
import accountSetup from "./views/accountSetup.sk";
import general from "./general/general.sk";
import form from "./general/form.sk";
import appLayout from "./general/appLayout.sk";
import collection from "./general/collection.sk";
import catalogue from "./views/catalogue.sk";
import publicItemsList from "./views/publicItemsList.sk";
import dashboard from "./views/dashboard.sk";
import chat from "./views/chat.sk";
import gdpr from "./views/gdpr.sk";
import itemLike from "./components/itemLike.sk";
import auth from "./views/auth.sk";
import publicItemsFilter from "./components/publicItemsFilter.sk";
import profile from "./views/profile.sk";
import error404 from "./views/error404.sk";
import collectors from "./views/collectors.sk";
import filter from "@/locales/components/filter.sk";
import collectorsFilter from "@/locales/components/collectorsFilter.sk";
import cookieConsent from "@/locales/components/cookieConsent.sk";
import dropdown from "@/locales/components/dropdown.sk";

export default {
  ...appLayout,
  ...form,
  ...general,
  ...collection,
  views: {
    ...about,
    ...addItem,
    ...auth,
    ...catalogue,
    ...contacts,
    ...collectors,
    ...dashboard,
    ...error404,
    ...chat,
    ...itemDetail,
    ...landingPages,
    ...accountSetup,
    ...magazine,
    ...profile,
    ...publicHomepage,
    ...publicItemsList,
    ...userSettings,
    ...gdpr,
  },
  components: {
    ...collectorsFilter,
    ...cookieConsent,
    ...filter,
    ...itemLike,
    ...publicItemsFilter,
    ...dropdown,
  },
};
