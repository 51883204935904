import { CollectionItem, UserSettings } from "@/config";
import { translate } from "@/services/translation";
import { InputValue } from "@/services/formInput";
import * as EmailValidator from "email-validator";
import { InvalidPasswordValueException } from "./user";
import { assertPrice, InvalidPriceException, isEmptyPrice, isNegotiablePrice } from "@/services/prices";

export class ValueNotValidException extends Error {}

// Decided by product team during "profiles" creation (February 2024).
// If you update this, don't forget to check error message "form.phonePatternExplanation".
export const PhoneRegex = new RegExp("^\\+?[0-9]{0,17}$");

// If you update this, don't forget to check error message "views.userSettings.errors.nicknameWrongFormat".
const NicknameRegex = new RegExp(`^[-0-9a-zA-Z]{${UserSettings.nicknameMinLength},${UserSettings.nicknameMaxLength}}$`);

/** @throws {ValueNotValidException} */
export const validatePublicUserName = (name: InputValue): void => {
  assertString(name, "name");

  const length = (name as string).length;
  if (length > UserSettings.nameMaxLength) {
    const msg = translate("views.userSettings.errors.publicNameTooLong", { maxLength: UserSettings.nameMaxLength });
    throw new ValueNotValidException(msg);
  }
};

/** @throws {ValueNotValidException} */
export const validatePhone = (phone: InputValue): void => {
  assertString(phone, "phone");
  const phoneString = phone as string;

  const isFilled = phoneString.length > 0;
  if (isFilled && !phoneString.match(PhoneRegex)) {
    const msg = translate("form.phonePatternExplanation");
    throw new ValueNotValidException(msg);
  }
};

/** @throws {ValueNotValidException} */
export const validateUserBio = (bio: InputValue): void => {
  assertString(bio, "bio");
  const bioString = bio as string;

  const length = bioString.length;
  if (length > UserSettings.bioMaxLength) {
    const msg = translate("views.userSettings.errors.bioTooLong", { maxLength: UserSettings.bioMaxLength });
    throw new ValueNotValidException(msg);
  }
};

/** @throws {ValueNotValidException} */
export const validateEmail = (email: InputValue, allowEmptyValue = true): void => {
  assertString(email, "e-mail");

  const emailString = email as string;
  const isFilled = emailString.length > 0;
  const isValid = EmailValidator.validate(emailString);
  const fillValid = allowEmptyValue || isFilled;

  if (!fillValid || !isValid) {
    const msg = translate("form.wrongEmailFormat");
    throw new ValueNotValidException(msg);
  }
};

/** @throws {ValueNotValidException} */
export const validateNickame = (nickname: InputValue): void => {
  assertString(nickname, "nickname");
  const nicknameString = nickname as string;

  const length = nicknameString.length;
  if (length < UserSettings.nicknameMinLength || length > UserSettings.nicknameMaxLength) {
    const msg = translate("views.userSettings.errors.nicknameWrongLength", { minLength: UserSettings.nicknameMinLength, maxLength: UserSettings.nicknameMaxLength });
    throw new ValueNotValidException(msg);
  }

  const isValid = nicknameString.match(NicknameRegex);
  if (!isValid) {
    const msg = translate("views.userSettings.errors.nicknameWrongFormat");
    throw new ValueNotValidException(msg);
  }
};

/** @throws {ValueNotValidException} */
export const validateUrl = (url: InputValue): URL | void => {
  assertString(url, "URL");

  try {
    let urlString = url as string;
    const isFilled = urlString.length > 0;
    if (isFilled) {
      if (!urlString.startsWith("http")) {
        urlString = "https://" + urlString;
      }
      return new URL(urlString);
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (e) {
    const msg = translate("form.wrongUrlFormat");
    throw new ValueNotValidException(msg);
  }
};

/**
 * Checks whether given string is valid URL and has certain domain.
 *
 * @throws {ValueNotValidException}
 */
export function validateDomainUrl(urlToValidate: InputValue, requestedDomain: string): void {
  const url = validateUrl(urlToValidate);
  if (url) {
    const domain = url.hostname.replace("www.", "");

    if (domain !== requestedDomain) {
      const msg = translate("form.wrongUrlDomain");
      throw new ValueNotValidException(msg);
    }
  }
}

/** @throws {InvalidPasswordValueException} */
export function validatePassword(password: InputValue): void {
  assertString(password, "Password");
  const passwordString = password as string;

  if (
    passwordString.length < UserSettings.passwordMinLength || // Is long enough?
    !passwordString.match(/[a-z]+/) || // Contains lowercase letter?
    !passwordString.match(/[A-Z]+/) || // Contains uppercase letter?
    !passwordString.match(/[0-9]+/) // Contains number?
  ) {
    const msg = translate("form.wrongPasswordFormat", { minLength: UserSettings.passwordMinLength });
    throw new InvalidPasswordValueException(msg);
  }
}

/** @throws {ValueNotValidException} */
export function validateCollectionItemNote(note: InputValue): void {
  const maxLength = CollectionItem.noteMaxLength;
  const maxMsg = translate("views.itemDetail.errors.noteTooLong", { maxLength });
  assertStringMaxLength(note, maxLength, maxMsg);
}

/** @throws {ValueNotValidException} */
export function validateCollectionItemWhereStored(value: InputValue): void {
  const minLength = CollectionItem.whereStoredMinLength;
  const maxLength = CollectionItem.whereStoredMaxLength;
  const minMsg = translate("views.itemDetail.errors.whereStoredTooShort", { minLength });
  const maxMsg = translate("views.itemDetail.errors.whereStoredTooLong", { maxLength });
  assertStringMaxLength(value, maxLength, maxMsg);
  validateStringMinLength(value, minLength, minMsg);
}

/** @throws {ValueNotValidException} */
export function validateCollectionItemAcquisitionSource(value: InputValue): void {
  const minLength = CollectionItem.acquisitionSourceMin;
  const maxLength = CollectionItem.acquisitionSourceMax;
  const minMsg = translate("views.itemDetail.errors.acquisitionSourceTooShort", { minLength });
  const maxMsg = translate("views.itemDetail.errors.acquisitionSourceTooLong", { maxLength });
  assertStringMaxLength(value, maxLength, maxMsg);
  validateStringMinLength(value, minLength, minMsg);
}

/** @throws {ValueNotValidException} */
export function validateCollectionItemName(value: InputValue): void {
  const minLength = CollectionItem.nameMinLength;
  const maxLength = CollectionItem.nameMaxLength;
  const minMsg = translate("views.itemDetail.errors.nameTooShort", { minLength });
  const maxMsg = translate("views.itemDetail.errors.nameTooLong", { maxLength });
  assertStringMaxLength(value, maxLength, maxMsg);
  validateStringMinLength(value, minLength, minMsg);
}

/** @throws {ValueNotValidException} */
export function validateCollectionItemPublicOfferedPrice(value: InputValue): void {
  const min = CollectionItem.publicOfferedPriceMin;
  const max = CollectionItem.publicOfferedPriceMax;
  const minMsg = translate("views.itemDetail.errors.publicOfferedPriceTooLow");
  const maxMsg = translate("views.itemDetail.errors.publicOfferedPriceTooHigh", { max });
  try {
    const price = assertPrice(value);
    if (isEmptyPrice(price) || isNegotiablePrice(price)) {
      return;
    }
    const amount = price.price;
    assertNumberMin(amount, min, minMsg, true);
    assertNumberMax(amount, max, maxMsg, true);
  } catch (e: unknown) {
    if (e instanceof InvalidPriceException) {
      throw new ValueNotValidException();
    } else {
      throw e;
    }
  }
}

/** @throws {ValueNotValidException} */
export function validateCollectionItemAcquirePrice(value: InputValue): void {
  try {
    const price = assertPrice(value);
    if (isEmptyPrice(price)) {
      return;
    }
    if (isNegotiablePrice(price)) {
      throw new ValueNotValidException();
    }
    const amount = price.price;
    const max = CollectionItem.acquirePriceMax;
    const min = CollectionItem.acquirePriceMin;
    const minMsg = translate("views.itemDetail.errors.acquirePriceTooLow", { min });
    const maxMsg = translate("views.itemDetail.errors.acquirePriceTooHigh", { max });
    assertNumberMin(amount, min, minMsg, true);
    assertNumberMax(amount, max, maxMsg, true);
  } catch (e: unknown) {
    if (e instanceof InvalidPriceException) {
      throw new ValueNotValidException();
    } else {
      throw e;
    }
  }
}

/** @throws {ValueNotValidException} */
export function validateCollectionItemCurrentPrice(value: InputValue): void {
  const min = CollectionItem.currentPriceMin;
  const max = CollectionItem.currentPriceMax;
  const minMsg = translate("views.itemDetail.errors.currentPriceTooLow", { min });
  const maxMsg = translate("views.itemDetail.errors.currentPriceTooHigh", { max });
  try {
    const isNullAllowed = false;
    const price = assertPrice(value);
    if (isEmptyPrice(price) || isNegotiablePrice(price)) {
      throw new ValueNotValidException(minMsg);
    }
    const amount = price.price;
    assertNumberMin(amount, min, minMsg, isNullAllowed);
    assertNumberMax(amount, max, maxMsg, isNullAllowed);
  } catch (e: unknown) {
    if (e instanceof InvalidPriceException) {
      throw new ValueNotValidException(minMsg);
    } else {
      throw e;
    }
  }
}

/** @throws {ValueNotValidException} */
export function validateCollectionItemPublicPieces(value: InputValue): void {
  const isNullAllowed = false;
  const min = CollectionItem.publicPiecesMin;
  const max = CollectionItem.publicPiecesMax;
  const minMsg = translate("views.itemDetail.errors.publicPiecesTooLow");
  const maxMsg = translate("views.itemDetail.errors.publicPiecesTooHigh", { max });
  assertNumberMin(value, min, minMsg, isNullAllowed);
  assertNumberMax(value, max, maxMsg, isNullAllowed);
}

/** @throws {ValueNotValidException} */
export function validateCollectionItemAcquireDate(value: InputValue): void {
  if (typeof value !== "string" || value === "") {
    const msg = translate("views.itemDetail.errors.wrongAcquireDate");
    throw new ValueNotValidException(msg);
  }
}

/** @throws {ValueNotValidException} */
export function validateCollectionItemPieces(value: InputValue): void {
  const isNullAllowed = false;
  const min = CollectionItem.piecesMin;
  const max = CollectionItem.piecesMax;
  const minMsg = translate("views.itemDetail.errors.piecesTooLow");
  const maxMsg = translate("views.itemDetail.errors.piecesTooHigh", { max });
  assertNumberMin(value, min, minMsg, isNullAllowed);
  assertNumberMax(value, max, maxMsg, isNullAllowed);
}

export function assertString(value: InputValue, valueName = "input value"): void {
  const type = typeof value;
  if (type !== "string") {
    throw new ValueNotValidException(`DEV: Should not happen. Type of "${valueName}" is "${type}", should be string.`);
  }
}

export function validateStringMinLength(value: InputValue, min: number, msg: string) {
  assertString(value);
  if ((value as string).length < min) {
    throw new ValueNotValidException(msg);
  }
}

function assertStringMaxLength(value: InputValue, max: number, msg: string) {
  assertString(value);
  if ((value as string).length > max) {
    throw new ValueNotValidException(msg);
  }
}

function assertNumber(value: InputValue): void {
  if (typeof value !== "number") {
    throw new ValueNotValidException(`DEV: Should not happen. Expected number.`);
  }
}

function assertNumberMin(value: InputValue, min: number, msg: string, allowNull = true) {
  if (allowNull && value === null) {
    return;
  }
  if (!allowNull && value === null) {
    throw new ValueNotValidException(msg);
  }

  assertNumber(value);
  if ((value as number) < min) {
    throw new ValueNotValidException(msg);
  }
}

function assertNumberMax(value: InputValue, max: number, msg: string, allowNull = true) {
  if (allowNull && value === null) {
    return;
  }
  if (!allowNull && value === null) {
    throw new ValueNotValidException(msg);
  }

  assertNumber(value);
  if ((value as number) > max) {
    throw new ValueNotValidException(msg);
  }
}
