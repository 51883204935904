/**
 * Shared catalogue data.
 */

import { CatalogueSearchItem } from "@/services/repositories/database";
import { ApiResponseImage } from "@/services/images";
import { Price, PriceNumericOrEmpty } from "@/services/prices";

export interface CataloguePrice {
  price: Price;
  date: string;
}

export enum CatalogueFilterFieldType {
  userInput,
  dropdown,
}

/**
 * Lego minifigs.
 */

export interface RawLegoMinifigData {
  id: number;
  images?: ApiResponseImage[];
  name: string;
  number: string;
  partsCount: number | null;
  lastPrice: PriceNumericOrEmpty;
  prices?: CataloguePrice[];
}

/**
 * Lego sets.
 */

export interface RawLegoSetData {
  id: number;
  number: string;
  name: string;
  year: number;
  theme?: RawLegoThemeData;
  partsCount: number;
  weight: number | null;
  dimensions: string | null;
  lastPrice: PriceNumericOrEmpty;
  prices?: CataloguePrice[];
  images?: ApiResponseImage[];
}

export interface RawLegoThemeData {
  id: number;
  name: string;
}

/**
 * Database history cache ( = filter + pagination).
 */

export type DatabaseFilterParameterValue = string | number | null;

export interface DatabaseFilterChangeEvent {
  value: number | null;
  name: string;
}

// New general filter interfaces (flexible storage preparation):

export const VinylsFilterYearFrom = 1917;
export const VinylsFilterYearTo = new Date().getFullYear();

export interface CatalogueFilterDropdownOption {
  label: string | number;
  value: string | number;
}

/**
 * Shared database API data.
 */

export interface RawCatalogueItemData {
  // Specific item detail (item card).
  id: number;
  name: string;
  images: ApiResponseImage[];
  parameters: RawCatalogueItemParameter[];
}

export interface RawCatalogueItemParameter {
  option: {
    value: string;
  };
  parameter: {
    id?: number;
    label: string;
    isWidgetIdentificator?: true;
  };
}

export const IsWidgetIdentificator = "isWidgetIdentificator";

export type CatalogueFilterParams = {
  // Sent to API
  categoryId: number;
  page: number;
  perPage: number;
  [key: string]: DatabaseFilterParameterValue;
};

export interface CatalogueSearchData {
  totalCount: number;
  items: CatalogueSearchItem[];
}

export interface CatalogueSearchResponse {
  // List of items in table, not specific item detail.
  totalCount: number;
  collectibles: SearchedItemRawData[];
}

export type SearchedItemRawData = RawCatalogueSearchedItemAlwaysPresent & (VinylsTableParams | BoardGamesTableParams);

interface RawCatalogueSearchedItemAlwaysPresent {
  id: number;
  name: string;
  images: ApiResponseImage[];
  categoryId: number;
}

interface VinylsTableParams {
  catNumber: string;
  price: string;
  interpret: string;
  year: string;
}

interface BoardGamesTableParams {
  author: string;
  language: string;
  year: string;
}

export interface CatalogueTableColumn {
  label: string;
  key: string;
  isPrice?: true;
}
