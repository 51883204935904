<template>
  <Dialog ref="dialog" :close-on-click-modal="false" :title="settings?.title">
    <div class="content-wrapper">
      <p v-if="settings.message" class="text" v-html="settings.message" />
      <component :is="settings.contentComponent" v-if="settings.contentComponent" />
    </div>
    <template #footer>
      <div class="footer">
        <div v-if="settings.showRemember" class="footer__remember">
          <InputCheckbox v-model="modelRememberMyChoice" :label="translate('doNotShowNextTime')" />
        </div>
        <div class="footer__buttons">
          <Button :variant="ButtonVariant.tertiary" @click="onReject">{{ settings.buttonCancelText }}</Button>
          <Button v-if="settings.buttonOkSecondaryText" :variant="ButtonVariant.secondary" @click="onAcceptSecondary">{{ settings.buttonOkSecondaryText }}</Button>
          <Button @click="onAccept">{{ settings.buttonOkText }}</Button>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts">
import Dialog from "@/components/basics/Dialog.vue";
import { useTranslation } from "@/composables";
import { Events } from "@/types";
import { Component, ref } from "vue";
import { onMounted, onUnmounted } from "vue";
import Button, { Variant as ButtonVariant } from "./Button.vue";
import InputCheckbox from "./InputCheckbox.vue";

export type OnConfirmCallback = (remember?: boolean) => void;

export type ConfirmationDialogSettings = {
  title?: string;
  message?: string;
  contentComponent?: Component;
  buttonOkText?: string;
  buttonOkSecondaryText?: string;
  buttonCancelText?: string;
  onConfirmCallback?: OnConfirmCallback;
  onConfirmSecondaryCallback?: OnConfirmCallback;
  onRejectCallback?: () => void;
  showRemember?: boolean;
};

export default {
  components: {
    Button,
    InputCheckbox,
    Dialog,
  },
  setup() {
    const { translate } = useTranslation();

    onMounted(() => window.eventBus.on(Events.confirm, show));
    onUnmounted(() => window.eventBus.off(Events.confirm, show));

    const dialog = ref<InstanceType<typeof Dialog> | null>(null);
    const open = () => dialog.value?.open();
    const close = () => dialog.value?.close();

    const settingsDefault = {
      title: undefined,
      message: undefined,
      buttonOkText: translate("yes"),
      buttonCancelText: translate("no"),
      onConfirmCallback: undefined,
      onRejectCallback: undefined,
      showRemember: false,
    };
    const settings = ref<ConfirmationDialogSettings>(settingsDefault);

    const modelRememberMyChoice = ref(false);

    const show = (settingsRecieved: ConfirmationDialogSettings) => {
      settings.value = {
        ...settingsDefault,
        ...settingsRecieved,
      };
      modelRememberMyChoice.value = false;
      open();
    };

    const onAccept = () => {
      if (settings.value?.onConfirmCallback) {
        if (settings.value.showRemember) {
          const remember = modelRememberMyChoice.value;
          settings.value.onConfirmCallback(remember);
        } else {
          settings.value.onConfirmCallback();
        }
      }
      close();
    };

    const onAcceptSecondary = () => {
      if (settings.value?.onConfirmSecondaryCallback) {
        if (settings.value.showRemember) {
          const remember = modelRememberMyChoice.value;
          settings.value.onConfirmSecondaryCallback(remember);
        } else {
          settings.value.onConfirmSecondaryCallback();
        }
      }
      close();
    };

    const onReject = () => {
      close();
      if (settings.value.onRejectCallback) {
        settings.value.onRejectCallback();
      }
    };

    return {
      dialog,
      settings,
      onAccept,
      onAcceptSecondary,
      onReject,
      ButtonVariant,
      modelRememberMyChoice,
      translate,
    };
  },
};
</script>

<style lang="scss" scoped>
.content-wrapper {
  margin-bottom: var(--spacing2x);
}
.text {
  margin: 0;
}
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--spacing2x);
  width: 100%;
  &__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: var(--spacing2x);
    margin-left: auto;
  }
}
</style>
