export type Image = {
  id: number;
  sizes: ImageSizesList;
  name: string;
};

export const factoryImage = (id: number, sizes: ImageSizesList, name = ""): Image => ({ id, sizes, name });

// <picture> tag settings
export interface ImageHtmlSettings {
  sources: {
    media: string;
    size: ImageSize;
  }[];
  fallbackSize: ImageSize;
}

export enum ImageSize {
  "85x64" = "85x64",
  "310x233" = "310x233",
  "467x350" = "467x350",
  "1200x900" = "1200x900",
  "1600x1200" = "1600x1200",
}

export type ImageUrls = { [extension: string]: string };
type ImageSizesList = { [size in ImageSize]?: ImageUrls };

export interface ApiResponseImage {
  id: number;
  imageUrl: ImageSizesList;
}

export type ApiResponseImageNoId = {
  imageUrl: ImageSizesList;
};
