import { ItemDetail, RawItemParameterData } from "@/types/item";
import dayjs, { Dayjs } from "dayjs";
import { Category } from "../categories/Category";
import { Tag, Tags } from "@/services/repositories/collection/tags";
import { Image } from "@/services/images";
import { CatalogueItem } from "@/services/repositories/database";
import { Entity } from "@/services/repositories/Entity";
import { LegoMinifig as CatalogueLegoMinifig } from "@/services/repositories/legoMinifigs";
import { LegoSet as CatalogueLegoSet } from "@/services/repositories/lego";
import { Parameter } from "@/services/repositories/collection/categories/parameters";
import { createEmptyPrice, isNumericPrice, Price } from "@/services/prices";

export enum ItemStatus {
  InMyCollection = "inCollection",
  OnMarketplace = "onMarketplace",
  Archived = "archived",
}

export class CollectionItem extends Entity {
  public dateAcquired: string;
  private category: Category;
  public name: string;
  public acquirePrice: Price;
  public currentPrice: Price;
  public pieces: number;
  public images: Image[] = [];
  public lastPrice: Price;
  public parameters: RawItemParameterData[] = [];
  public whereStored: string;
  public privateNote: string;
  public publicNote: string;
  public publicOfferedPrice: Price;
  public publicPieces: number;
  public userCollectionList: Set<string>;
  public likesCount: number | undefined; // not defined for item detail card
  public acquisitionSource: string;
  public status: ItemStatus | undefined;

  public detail: ItemDetail = null;

  private tags: Tag[];

  public setName(name: string): void {
    this.name = name;
  }

  public getName(): string {
    return this.name;
  }

  public getDateAcquired(asString = false): string | Dayjs {
    if (asString) {
      return this.dateAcquired;
    } else {
      return dayjs(this.dateAcquired);
    }
  }

  public setDateAcquired(newDate: string) {
    this.dateAcquired = newDate;
  }

  public getAcquirePrice(): Price {
    return this.acquirePrice;
  }

  public setAcquirePrice(newPrice: Price): void {
    this.acquirePrice = newPrice;
  }

  public setCurrentPrice(newPrice: Price): void {
    this.currentPrice = newPrice;
  }

  public getCurrentPrice(): Price {
    if (this.currentPrice) {
      return this.currentPrice;
    } else if (this.lastPrice) {
      if (isNumericPrice(this.lastPrice)) {
        return this.lastPrice;
      } else {
        throw new Error("DEV: Should not happen.");
      }
    } else {
      return createEmptyPrice();
    }
  }

  public setPiecesCount(piecesCount: number): void {
    this.pieces = piecesCount;
  }

  public getPiecesCount(): number {
    return this.pieces;
  }

  public setPrivateNote(note: string): void {
    this.privateNote = note;
  }

  public setWhereStored(where: string): void {
    this.whereStored = where;
  }

  public getCoverImage(): Image | null {
    return this.images.length > 0 ? this.images[0] : null;
  }

  public getImages(): Image[] {
    return this.images;
  }

  public getParameterValue(parameterId: number): RawItemParameterData | undefined {
    return this.parameters.find((param) => {
      return param.parameterId == parameterId;
    });
  }

  public updateParameterValue(parameter: Parameter, value: number | string, valueId: number): void {
    const isCustomValue = typeof value === "string";
    const relation = this.parameters.find((p) => p.parameterId === parameter.id);
    if (relation) {
      relation.isCustom = isCustomValue ? 1 : 0;
      relation.valueId = valueId;
      relation.value = isCustomValue ? value : "";
    } else {
      this.parameters.push({
        isCustom: isCustomValue ? 1 : 0,
        parameterId: parameter.id,
        value: isCustomValue ? value : "",
        valueId: valueId,
      });
    }
  }

  public setCategory(category: Category): void {
    this.category = category;
  }

  public getCategory(): Category {
    if (this.category === null) {
      throw new Error("Item: Category was not specified. Cannot return it.");
    }

    return this.category;
  }

  public hasCategory(categoryId: number): boolean {
    return this.getCategory().getId() === categoryId;
  }

  public setImages(images: Image[]): void {
    this.images = images;
  }

  public setTags(tags: Tag[]): void {
    this.tags = tags;
  }

  public async getTags(): Promise<Tag[]> {
    if (typeof this.tags === "undefined") {
      return Tags.getForItem(this.getId()).then((tags) => {
        this.tags = tags;
        return tags;
      });
    } else {
      return new Promise((resolve) => resolve(this.tags));
    }
  }

  public removeTag(tag: Tag): void {
    if (!this.tags) {
      return;
    }

    const index = this.tags.indexOf(tag);
    this.tags.splice(index, 1);
  }

  public getDetail(): ItemDetail {
    return this.detail;
  }

  public setDetail(detail: CatalogueItem): void {
    this.detail = detail;
  }

  public isPublic(): boolean {
    return this.userCollectionList.size > 0;
  }

  public removeFromList(listName: string): void {
    this.userCollectionList.delete(listName);
  }

  public addIntoList(listName: string): void {
    this.userCollectionList.add(listName);
  }
}

/**
 * Old-api entity - will be removed when Lego minifigs category will be converted into new api.
 */
export class LegoMinifig extends CollectionItem {
  constructor(
    public id: number,
    public detail: CatalogueLegoMinifig
  ) {
    super(id);
  }

  public getNumber(): string {
    return this.getDetail().getNumber();
  }

  public getDetail(): CatalogueLegoMinifig {
    return this.detail;
  }

  get partsCount(): number | null {
    return this.getDetail().partsCount;
  }
}

/**
 * Old-api entity - will be removed when Lego sets category will be converted into new api.
 */
export class LegoSet extends CollectionItem {
  constructor(
    public id: number,
    public detail: CatalogueLegoSet
  ) {
    super(id);
  }

  public getDetail(): CatalogueLegoSet {
    return this.detail;
  }
}
