import { Entity } from "../../Entity";

class Tag extends Entity {
  private count: number;

  constructor(
    public id: number,
    private label: string
  ) {
    super(id);
  }

  public getLabel(): string {
    return this.label;
  }

  public setCount(count: number) {
    this.count = count;
  }

  public getCount(): number {
    if (!this.isCountAvailable()) {
      throw "DEV: Count was not defined.";
    }

    return this.count;
  }

  public isCountAvailable(): boolean {
    return typeof this.count !== "undefined";
  }

  /** Only for old DropdownAutocomplete.vue component. Can be removed when DropdownAutocomplete.vue will use DropdownOption.ts */
  public get name(): string {
    return this.getLabel();
  }
}

export { Tag };
