<template>
  <div :class="['overlay-message', cssClass]">
    <div v-if="showSpinner" class="overlay-message__spinner">
      <loading-big />
    </div>
    <div class="overlay-message__text">
      {{ text }}
    </div>
  </div>
</template>

<script>
import { Events } from "@/types";
import LoadingBig from "./LoadingBig.vue";

export default {
  components: { LoadingBig },
  data() {
    return {
      text: "",
      showSpinner: true,
      cssClass: "",
    };
  },
  mounted() {
    window.eventBus.on(Events.showTextOverlay, this.show);
    window.eventBus.on(Events.hideTextOverlay, this.hide);
  },
  unmounted() {
    window.eventBus.off(Events.showTextOverlay, this.show);
    window.eventBus.off(Events.hideTextOverlay, this.hide);
  },
  methods: {
    show(settings) {
      if (settings && (settings.showSpinner || settings.spinner)) {
        this.showSpinner = true;
      } else {
        this.showSpinner = false;
      }

      this.text = settings ? settings.text : "";
      this.cssClass = "overlay-message--visible";
    },
    hide() {
      this.cssClass = "overlay-message--hiding";

      setTimeout(() => {
        this.cssClass = "";
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay-message {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(#000, 0.9);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  opacity: 0;
  display: none;
  transition: opacity 0.3s ease;
  &--visible {
    opacity: 1;
    display: flex;
    z-index: 9999;
  }
  &--hiding {
    display: flex;
    opacity: 0;
  }
  &__text {
    color: #fff;
    font-size: 25px;
    font-weight: 400;
    width: 90%;
    max-width: 500px;
    text-align: center;
  }
  &__spinner {
    position: relative;
    width: 100px;
    height: 100px;
  }
}
</style>
