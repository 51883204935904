<template>
  <ElDialog v-model="isOpen" v-bind="attrs" class="basic-dialog" :close-icon="closeIcon" modal-class="basic-dialog-overlay">
    <template v-if="attrs.title" #header>
      <div class="basic-dialog__header">
        <Heading2 class="basic-dialog__title">
          <Icon v-if="icon" :icon="icon" class="basic-dialog__title__icon" />
          {{ attrs.title }}
        </Heading2>
      </div>
    </template>
    <div class="basic-dialog__body">
      <slot></slot>
    </div>
    <template v-if="$slots.footer" #footer>
      <div class="basic-dialog__footer">
        <slot name="footer"></slot>
      </div>
    </template>
  </ElDialog>
</template>

<script setup lang="ts">
import { ElDialog } from "element-plus";
import { defineAsyncComponent, h, ref, useAttrs } from "vue";
import Icon, { iconProperty } from "./Icon.vue";
import ButtonIcon, { Variant as ButtonIconVariant } from "./ButtonIcon.vue";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "@/composables";

const Heading2 = defineAsyncComponent(() => import("@/components/basics/Heading2.vue"));

defineProps({
  // Type is defined inside iconProperty.
  // eslint-disable-next-line vue/require-prop-types
  icon: {
    ...iconProperty,
    required: false,
  },
});

const attrs = useAttrs();
const { translate } = useTranslation();

const isOpen = ref(false);

const open = () => (isOpen.value = true);
const close = () => (isOpen.value = false);
const toggleOpen = () => (isOpen.value = !isOpen.value);
const closeIcon = h(ButtonIcon, { icon: faXmark, variant: ButtonIconVariant.mediumContrast, title: translate("close") });

defineExpose({
  open,
  close,
  toggleOpen,
});
</script>

<style lang="scss">
.basic-dialog-overlay {
  .el-overlay-dialog {
    display: flex;
    align-items: center;
  }
}
.basic-dialog {
  --dialogWidth: min(500px, 90vw);
  --borderRadius: var(--radius4x);

  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.15);
  border-radius: var(--borderRadius);
  padding: var(--spacing6x) var(--spacing4x) var(--spacing4x) var(--spacing4x);
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  margin-top: auto;
  margin-bottom: auto;
  &.el-dialog {
    --el-dialog-width: var(--dialogWidth);
  }
  .el-dialog {
    &__header {
      padding-bottom: 0;
    }
    // close btn
    &__headerbtn {
      top: var(--spacing1x);
      right: var(--spacing1x);
      width: auto;
      height: auto;
      .el-icon {
        height: auto;
        width: auto;
      }
    }
    &__body {
      font: 15px/1.4 var(--font-family);
      color: var(--black);
      display: flex;
      flex-direction: column;
      gap: var(--spacing3x);
      overflow: hidden;
    }
    &__footer {
      padding-top: var(--spacing3x);
    }
  }
  &__header {
    padding-bottom: var(--spacing3x);
  }
  &__title {
    display: flex;
    align-items: center;
    gap: calc(var(--spacing3x) / 2);
    margin-top: 0 !important; // Overwrite default heading style
    margin-bottom: 0 !important; // Overwrite default heading style
    &__icon {
      width: 19px !important;
      height: 19px !important;
      flex-shrink: 0;
    }
  }
  &__body {
    overflow: auto;
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    gap: var(--spacing2x);
  }

  @include for-size(sm) {
    --dialogWidth: 100dvw;
    --borderRadius: 0;

    height: 100dvh;
    max-height: 100dvh;
    margin: 0;

    .el-dialog {
      &__body {
        flex-grow: 1;
      }
    }
    &__footer {
      margin-top: auto;
    }
  }
}
</style>
