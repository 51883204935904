import { LockingStateRouteName, router } from "@/router";
import { CountryCode, FallbackCountryCode } from "@/services/translation";
import { useUserStore, store as pinia } from "@/store";
import { isStringInEnum } from "@/utilities";
import axios from "axios";

const userStore = useUserStore(pinia);

const AppId = import.meta.env.VITE_FB_APP_ID;
const AppSecret = import.meta.env.VITE_FB_APP_SECRET;

/**
 * @return Callback URL (already encoded via encodeURI()).
 */
const getCallbackUrl = (): string => {
  const urlRelative = router.resolve({ name: LockingStateRouteName.facebookSsoCallback });
  const urlAbsolute = window.location.origin + urlRelative.href;
  const urlEndcoded = encodeURI(urlAbsolute);
  return urlEndcoded;
};

/**
 * Our data returned back together with redirect to getCallbackUrl().
 */
type RedirectState = {
  redirectUrl?: string;
  countryCode: string;
};

const getCallbackData = (): RedirectState => {
  const route = router.currentRoute.value;
  const redirectUrl = route.query.redirect_url as string | undefined;
  const countryCode = (route.params.countryCode as string | undefined) ?? FallbackCountryCode;
  return { redirectUrl, countryCode };
};

/**
 * Redirects to Facebook login page. After successfull login, user is redirected back to our page - LockingStateRouteName.facebookSsoCallback.
 */
const login = (): void => {
  const callbackUrl = getCallbackUrl();
  const callbackData = getCallbackData();
  const callbackDataEncoded = encodeURIComponent(JSON.stringify(callbackData));
  const scope = "email";
  const responseType = "code";
  const url = `https://www.facebook.com/v19.0/dialog/oauth?scope=${scope}&response_type=${responseType}&client_id=${AppId}&redirect_uri=${callbackUrl}&state=${callbackDataEncoded}`;
  window.location.href = url;
};

/**
 * Calls Facebook Graph API and gets user access_token in exchange for user "code".
 */
const exchangeCodeForToken = async (code: string): Promise<string> => {
  const callbackUrl = getCallbackUrl();
  const url = `https://graph.facebook.com/v21.0/oauth/access_token?client_id=${AppId}&redirect_uri=${callbackUrl}&client_secret=${AppSecret}&code=${code}`;
  const response = await axios.get(url);
  const token = response.data.access_token;
  return token;
};

const logout = async (): Promise<void> => {
  const accessToken = userStore.getSsoAccessToken();
  return axios
    .request({
      url: "https://graph.facebook.com/me/permissions?access_token=" + accessToken,
      method: "DELETE",
    })
    .then(() => userStore.clearSsoAccessToken());
};

class InvalidRedirectStateException extends Error {}

const parseRedirectState = (stateString: string): RedirectState => {
  let json;
  try {
    json = JSON.parse(stateString);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (e: unknown) {
    throw new InvalidRedirectStateException();
  }
  let countryCode;
  if ("countryCode" in json && typeof json.countryCode === "string" && isStringInEnum(json.countryCode, CountryCode)) {
    countryCode = json.countryCode;
  } else {
    throw new Error("DEV: Should not happen.");
  }

  const state: RedirectState = { countryCode };

  if ("redirectUrl" in json && typeof json.redirectUrl === "string") {
    state.redirectUrl = json.redirectUrl; // Maybe some check if it's a valid URL in our domain?
  }
  return state;
};

export const Facebook = {
  login,
  exchangeCodeForToken,
  parseRedirectState,
  logout,
  InvalidRedirectStateException,
};
