import { User } from "@/services/user";
import { BuildMode } from "@/types";

export enum EventName {
  MessagingIWantToTalkClick = "mkt_messages-funnel-click",
  MessagingConversationStarted = "mkt_messages-funnel-first-message",
  MessagingFirstReaction = "mkt_messages-funnel-first-reaction",
  AiVisionIdentificationCompleted = "dev_ai_vision_identification_completed",
  AiVisionShareOpinionClick = "dev_ai_vision_share_opinion_click",
  AiVisionIdentificationSuccessful = "dev_ai_vision_identification_successful",
  AiVisionIdentificationUnsuccessful = "dev_ai_vision_identification_unsuccessful",
  AiVisionItemCreated = "dev_ai_vision_item_created",
}

export type PostHogObject = {
  capture: (...args: unknown[]) => void;
  identify: (id: string) => void;
};

export const trackEvent = async (event: EventName): Promise<void> => {
  if (isTrackingAllowed()) {
    getPostHogObject()?.capture(event);
  }
};

export const identifyUser = async (user: User): Promise<void> => {
  if (isTrackingAllowed()) {
    getPostHogObject()?.identify(user.getEmail());
  }
};

const isTrackingAllowed = (): boolean => {
  const allowedModes = [BuildMode.production, BuildMode.beta];
  const mode = import.meta.env.MODE as BuildMode;
  return allowedModes.includes(mode);
};

const getPostHogObject = (): PostHogObject | undefined => {
  if (typeof window.posthog === "undefined") {
    console.warn("DEV: Should not happen. PostHog not found.");
    return undefined;
  } else {
    return window.posthog;
  }
};
