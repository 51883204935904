import { RouteLocationRaw, RouteRecordRaw } from "vue-router";
import { gettext } from "@/services/translation";
import { createLocalizedRoute } from "@/router/helpers";
import { Layout } from "@/router/routeMeta";

export enum ErrorRouteName {
  error404 = "error404",
  ActionFailed = "errorActionFailed",
}

export const errorPagesRoutes: RouteRecordRaw[] = [
  {
    name: ErrorRouteName.ActionFailed,
    path: "/:countryCode(cz|sk)/error/action-failed",
    components: { mainContent: () => import("@/views/error/ActionFailed.vue") },
    meta: {
      public: true,
      title: () => gettext.$gettext("Tuto akci není možné provést"),
      layout: Layout.Public,
      layoutLoggedIn: Layout.App,
    },
  },
  {
    name: ErrorRouteName.error404,
    path: "/:countryCode(cz|sk)/:pathMatch(.*)",
    components: { mainContent: () => import("@/views/error/NotFound404.vue") },
    meta: {
      public: true,
      title: () => gettext.$gettext("Oops! Zabloudil jsi…"),
      layout: Layout.Public,
      layoutLoggedIn: Layout.App,
      breadcrumbs: [],
    },
  },
];

export const createError404Link = (): RouteLocationRaw =>
  createLocalizedRoute({
    name: ErrorRouteName.error404,
    params: {
      pathMatch: "404",
    },
  });
