import { useUser } from "@/composables";
import { getUserForRouter } from "@/router/helpers";
import { createError404Link } from "@/router/routes";
import { Right } from "@/services/user/internalUserTypes";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

export const checkUserHasRight = (requestedRight: Right) => {
  return async (_: RouteLocationNormalized, __: RouteLocationNormalized, next: NavigationGuardNext) => {
    const user = await getUserForRouter();
    const { hasRight } = useUser();
    if (user === undefined || !hasRight(requestedRight, user)) {
      next(createError404Link());
    } else {
      next();
    }
  };
};
