export default {
  profile: {
    my: {
      settingsBtn: "@:views.userSettings.heading",
    },
    public: {
      breadcrumbs: "Profil zberateľa { nickname }",
    },
    noContact: "Používateľ nemá vyplnené osobné údaje.",
    sendMessage: "Napísať zberateľovi",
    sharePanel: {
      heading: "Zdieľaj svoju zbierku",
      toggleLabel: "Verejný profil",
      text: "Skopíruj si odkaz na svoj profil a zdieľaj ho s ostatnými zberateľmi. Využiť ho môžeš napríklad v predajných skupinách alebo ako prezentáciu svojej zbierky.",
      linkCopiedMsg: "Hurá! Odkaz na tvoj profil skopírovaný do schránky. Zdieľaj svoju zbierku. :)",
      openPofileBtn: "Zobraziť profil",
    },
    items: {
      heading: "Zverejnené predmety",
      emptyResult: {
        activeFilter: "Bohužiaľ, tvojmu filtru nevyhovuje žiadna položka. Skús vyhľadávanie upraviť, možno sa tu tvoj vybraný kúsok niekde schováva.",
        myProfile: {
          heading: "Žiadny predmet",
          text: "Tu ty a ostatní zberatelia uvidíte všetky tvoje zverejnené predmety, ktoré máš vo",
          textLink: "zbierke",
          cta: "Pridať predmet",
        },
        publicProfile: {
          heading: "Žiadny predmet",
          text1: "Používateľ aktuálne nemá",
          text2: "žiadny zverejnený predmet.",
        },
      },
      error: "Ops. Chyba vyhľadávania.",
      offeringFor: "Cena",
      filter: {
        categoryPlaceholder: "Všetky kategórie",
      },
    },
  },
};
