import { TokenInUrlStringReplacement } from "@/services/api/API";
import { Response401Exception } from "./apiExceptions";

/** Helper for filtering out 401 exception -> do not let it bubble further. */
export function catch401(e: unknown): void {
  if (e instanceof Response401Exception) {
    return;
  } else {
    throw e;
  }
}

/**
 * @deprecated
 * For old-style-api URLs (token is in URL, not in headers).
 * Do not create this kind of URLs anymore.
 */
export function addTokenToUrl(relativeApiUrl: string): string {
  return TokenInUrlStringReplacement + "/" + relativeApiUrl;
}
