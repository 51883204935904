import { NewReleasePanelShownEvent, trackEvent } from "@/services/analytics/googleAnalytics";
import { useAppState } from "@/store/appState";
import axios from "axios";
import { computed } from "vue";
import { watch } from "vue";

export function useVersion() {
  const appState = useAppState();
  const version = __APP_VERSION__;
  const versionFile = "/version.json"; // Must be the same as file created by deployment script
  const isLatest = computed(() => appState.isLatestVersion);

  /**
   * Checks if current bundle version is latest.
   * SIDE EFFECT!!! Updates "isLatest" value in store!
   */
  async function checkIsLatest(): Promise<boolean> {
    const latestVersion = await getLatestVersion();
    const latestWeight = countVersionWeight(latestVersion);
    const currentWeight = countVersionWeight(version);
    const is = latestWeight <= currentWeight;
    appState.setIsLatestVersion(is);
    return isLatest.value;
  }

  /**
   * Loads latest version from some external source (file actually in this case).
   */
  async function getLatestVersion(): Promise<string> {
    const headers = { "Cache-Control": "no-cache" };
    return axios.get(versionFile, { headers }).then((response) => response.data.version ?? "");
    // intentionally no catch() -> let's log erros into console
  }

  /**
   * Works only with none or one char at the end of version name. With two chars, it'll not work.
   */
  function countVersionWeight(version: string): number {
    const str = version.replace(/\./g, "");
    const lastChar = str.slice(-1);

    let weight = parseInt(str);
    if (isNaN(parseInt(lastChar))) {
      weight += lastChar.charCodeAt(0) / 100;
    }

    return weight;
  }

  /**
   * Send info into Google Analytics.
   */
  watch(
    () => isLatest.value,
    () => {
      if (isLatest.value === false) {
        trackEvent(new NewReleasePanelShownEvent());
      }
    }
  );

  function logVersionIntoConsole(): void {
    console.log("%c" + version, "padding: 4px 8px; color: #fff; background: #32C380; border-radius: 5px");
  }

  return {
    version,
    isLatest,
    checkIsLatest,
    logVersionIntoConsole,
  };
}
