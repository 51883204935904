/**
 * A GDoc (linked below) detailing each event is available.
 * Please remember to update the GDoc if you make any changes to this file.
 *
 * https://docs.google.com/document/d/1Oab_s89fJhpLlJ417DqXnZ-bEJDX7HSJDe4NE6tkudU/edit
 */

import { AccountType } from "../user/internalUserTypes";

abstract class GaEvent {
  public readonly name: string;
  public params: Record<string, number | string>;
}

export class AccountCreatedEvent implements GaEvent {
  public readonly name = "account_created";
  constructor(
    private accountId: number,
    private accountType: AccountType
  ) {}
  public get params() {
    return {
      id: this.accountId,
      type: this.accountType === AccountType.common ? "in-site" : this.accountType,
    };
  }
}

export class AccountVerifiedEvent implements GaEvent {
  public readonly name = "account_verified";
  constructor(
    private accountId: number,
    private accountType: AccountType
  ) {}
  public get params() {
    return {
      id: this.accountId,
      type: this.accountType === AccountType.common ? "in-site" : this.accountType,
    };
  }
}

export class LoginEvent implements GaEvent {
  public readonly name = "account_login";
  constructor(private accountId: number) {}
  public get params() {
    return {
      id: this.accountId,
    };
  }
}

export class NewReleasePanelShownEvent implements GaEvent {
  public readonly name = "new_release_panel_shown";
  public params = {};
}

export function trackEvent(event: GaEvent): void {
  window.dataLayer.push({
    event: event.name,
    params: event.params,
  });
}
